import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ElementsSelect,
  NOT_SET,
  PrettySelect,
  getOptionValue,
} from "../helpers/Forms";
import {
  Context,
  type InputConversion,
  type ContextProviderType,
  type Elements,
  type GoogleAdsAccount,
  type GoogleAdsAccounts,
} from "./ContextProvider";
import { type OptionsOrGroups } from "react-select";
import { Icon } from "../helpers/Icons";

export interface SearchRequest {
  dimension: "query" | "page";
  element: string;
  site_url: string;
}

export function getDefaultSearchRequest(): SearchRequest {
  return {
    element: NOT_SET,
    site_url: NOT_SET,
    dimension: "page",
  };
}

interface GoogleSearchRequestProps {
  request: SearchRequest;
  elements: Elements;
  onChange: (request: SearchRequest) => void;
  loading: boolean;
}

interface DimensionSelectProps {
  inputId?: string;
  value: string | null;
  onChange: (value: SearchRequest["dimension"]) => void;
  disabled: boolean;
}

interface SiteUrlSelectProps {
  inputId?: string;
  value: string | null;
  onChange: (value: string | null) => void;
}

interface CustomerIdSelectProps {
  inputId?: string;
  value: string | null;
  isDisabled: boolean;
  onChange: (value: string | null) => void;
}

export function invalidInputForGoogle(
  inputType: InputConversion["type"] | null,
  inputArgs: InputConversion["args"] | null,
) {
  if (inputType === "GOOGLE_SEARCH_ANALYTICS") {
    const request = inputArgs as SearchRequest;
    return request.element === NOT_SET || request.site_url === NOT_SET;
  } else {
    return false;
  }
}

export function getOutputTypes(inputArgs: SearchRequest) {
  if (inputArgs.dimension === "page") {
    return [
      "GOOGLE_SEARCH_CLICKS",
      "GOOGLE_SEARCH_IMPRESSIONS",
      "GOOGLE_SEARCH_CTR",
      "GOOGLE_SEARCH_POSITION",
    ];
  } else {
    return [
      "GOOGLE_SEARCH_CLICKS",
      "GOOGLE_SEARCH_IMPRESSIONS",
      "GOOGLE_SEARCH_CTR",
      "GOOGLE_SEARCH_POSITION",
      "GOOGLE_SEARCH_TOP_URL",
    ];
  }
}

function DimensionSelect({
  inputId,
  value,
  onChange,
  disabled,
}: DimensionSelectProps) {
  const { t } = useTranslation();

  const options = [
    { value: "page", label: t("element.google.dimension.page.label") },
    { value: "query", label: t("element.google.dimension.query.label") },
  ];
  const selectedOption = options.find((option) => option.value === value);
  return (
    <PrettySelect
      inputId={inputId}
      value={selectedOption}
      onChange={(option) => {
        onChange(getOptionValue<SearchRequest["dimension"]>(option) || "page");
      }}
      options={options}
      isDisabled={disabled}
    />
  );
}

function sortDomains(domains: string[]) {
  const urls = [];
  const scDomains = [];

  for (const domain of domains) {
    if (domain.startsWith("sc-domain:")) {
      scDomains.push(domain);
    } else {
      urls.push(domain);
    }
  }

  urls.sort();
  scDomains.sort();
  return urls.concat(scDomains);
}

function SiteUrlSelect({ inputId, value, onChange }: SiteUrlSelectProps) {
  const context = useContext<ContextProviderType>(Context);

  const options = sortDomains(context.googleSites).map((site) => {
    return { value: site, label: site };
  });
  const selectedOption = options.find((option) => option.value === value);

  return (
    <PrettySelect
      inputId={inputId}
      value={selectedOption}
      onChange={(option) => {
        onChange(getOptionValue<string>(option));
      }}
      options={options}
    />
  );
}

export function GoogleSearchArea({
  request,
  elements,
  onChange,
  loading,
}: GoogleSearchRequestProps) {
  const context = useContext<ContextProviderType>(Context);

  useEffect(() => {
    if (!context.googleSitesLoaded || context.googleSitesError !== null) {
      context.loadGoogleSites();
    }
  }, []);

  const { t } = useTranslation();
  const onChangeElement = (element: string | null) => {
    if (element) {
      request.element = element;
      onChange(request);
    }
  };
  const onChangeDimension = (value: SearchRequest["dimension"]) => {
    request.dimension = value;
    onChange(request);
  };

  const onChangeSiteUrl = (value: string | null) => {
    if (value) {
      request.site_url = value;
      onChange(request);
    }
  };

  return (
    <div>
      <div className="input-group element-select">
        <label htmlFor="element-input-args">
          {t("element.select.input.label")}
        </label>
        <ElementsSelect
          inputId="element-input-args"
          elements={elements}
          value={request.element}
          onChange={onChangeElement}
          loading={loading}
        />
      </div>
      <div className="input-group site-url-select">
        <label htmlFor="output-item-value-select">
          {t("element.google.siteUrl.select.label")}
        </label>
        <SiteUrlSelect
          inputId="output-item-value-select"
          value={request.site_url || null}
          onChange={onChangeSiteUrl}
        />
      </div>
      <div className="input-group dimension-select">
        <label htmlFor="dimension-select">
          {t("element.google.dimension.select.label")}
        </label>
        <DimensionSelect
          inputId="dimension-select"
          value={request.dimension}
          onChange={onChangeDimension}
          disabled={request.element === NOT_SET}
        />
      </div>
    </div>
  );
}

const createGoogleAdsAccountsOptions = (
  email: string,
  accounts: GoogleAdsAccounts,
): OptionsOrGroups<any, any> => {
  const pairs: object[] = [];

  for (const loginCustomerId in accounts) {
    accounts[loginCustomerId].forEach((account: GoogleAdsAccount) => {
      let label = (
        <span>
          {" "}
          {account.descriptiveName} - {account.id}
        </span>
      );
      if (account.manager) {
        label = (
          <b>
            {account.descriptiveName} - {account.id}
          </b>
        );
      }
      pairs.push({ value: `${email}|${loginCustomerId}|${account.id}`, label });
    });
  }

  return pairs;
};

export function CustomerIdSelect({
  inputId,
  value,
  onChange,
  isDisabled,
}: CustomerIdSelectProps) {
  const context = useContext<ContextProviderType>(Context);
  const email = value?.split("|")[0];
  const { t } = useTranslation();
  const [placeholder, setPlaceholder] = useState<React.ReactNode>(
    <div>
      {t("forms.select.loading.label")} <Icon name="loading" />
    </div>,
  );
  const [disabled, setDisabled] = useState<boolean>(true);

  let options: OptionsOrGroups<any, any> = [];
  let selectedOption;

  useEffect(() => {
    if (
      !isDisabled &&
      (!context.googleAdsAccountsLoaded ||
        context.googleAdsAccountsError !== null)
    ) {
      const callback = () => {
        setPlaceholder(t("element.select.prompt.label"));
        setDisabled(false);
      };
      context.loadGoogleAdsAccounts(callback, callback);
    } else {
      setPlaceholder(t("element.select.prompt.label"));
      setDisabled(isDisabled);
    }
  }, []);

  if (isDisabled) {
    selectedOption = { value: "selected", label: value?.split("|")[2] };
  } else {
    options = createGoogleAdsAccountsOptions(
      email || "",
      context.googleAdsAccounts,
    );
    selectedOption = options.find((option) => option.value === value);
  }

  return (
    <PrettySelect
      inputId={inputId}
      value={selectedOption}
      placeholder={placeholder}
      onChange={(option) => {
        onChange(getOptionValue<string>(option));
      }}
      options={options}
      isDisabled={disabled}
    />
  );
}
