import { type i18n } from "i18next";
import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import readVar from "../helpers/Config";
import { ErrorCodeMessage } from "../helpers/Messages";
import { getProjectId } from "../helpers/Navigate";
import { type Question as OpenAiQuestion } from "./OpenAi";
import { type Request as IceCatRequest } from "./IceCat";
import { type SearchRequest as GoogleSearchRequest } from "./Google";

export class APIError extends Error {
  status: number;

  constructor(msg: string, status: number) {
    super(msg);
    this.status = status;
    Object.setPrototypeOf(this, APIError.prototype);
  }
}

export interface TokenType {
  access_token: string;
  user_id: string;
  user_name?: string;
  expires_at: string;
  locale: string;
}

export type Elements = Record<string, Element>;

export interface Element {
  id?: number;
  name?: string;
  is_attribute: boolean;
  hidden: boolean;
  origin: string;
  order?: number;
  children?: Elements;
}

export interface InputConversion {
  type:
    | "SKLIK_KEYWORD"
    | "OPEN_AI_QUESTION"
    | "ICE_CAT_REQUEST"
    | "GOOGLE_SEARCH_ANALYTICS"
    | "GOOGLE_ADS";
  args: string | IceCatRequest | OpenAiQuestion | GoogleSearchRequest;
}

export interface OutputConversion {
  type:
    | "SKLIK_AVG_CPC"
    | "SKLIK_AVG_SEARCH_COUNT"
    | "GPT35_ANSWER"
    | "GPT35_INSTRUCT_ANSWER"
    | "GPT40_ANSWER"
    | "GPT4O_ANSWER"
    | "DAVINCI002_ANSWER"
    | "BABBAGE002_ANSWER"
    | "ICE_CAT_RESPONSE"
    | "GOOGLE_SEARCH_CLICKS"
    | "GOOGLE_SEARCH_IMPRESSIONS"
    | "GOOGLE_SEARCH_CTR"
    | "GOOGLE_SEARCH_POSITION"
    | "GOOGLE_SEARCH_TOP_URL"
    | "GOOGLE_ADS_AVERAGE_CPC"
    | "GOOGLE_ADS_CLICKS"
    | "GOOGLE_ADS_CTR"
    | "GOOGLE_ADS_COST"
    | "GOOGLE_ADS_CONVERSIONS"
    | "GOOGLE_ADS_CONVERSIONS_VALUE"
    | "GOOGLE_ADS_IMPRESSIONS"
    | "GOOGLE_ADS_PRODUCT_STATE";
  args: string;
}

export interface ElementMapping {
  id?: number;
  is_active: boolean;
  input: InputConversion;
  output: OutputConversion;
  query_id?: number;
}

export interface ElementsMappings {
  available_data_sources: string[];
  errors: string[];
  data: ElementMapping[];
}

export interface Query {
  id: string;
  name: string;
  project_id: string;
  search_output: boolean;
  product_count: number;
}

export interface Credentials {
  project_id: number;
  token: string;
  type: string;
}

export interface GoogleAdsAccount {
  id: string;
  manager: boolean;
  descriptiveName: string;
}

export type GoogleAdsAccounts = Record<string, GoogleAdsAccount[]>;

interface ContextProviderProps {
  children: React.ReactNode;
  i18n: i18n;
  searchParams: URLSearchParams;
}

interface ContextProviderState {
  // Authentication
  projectId: string | null;
  token: TokenType | null;
  tokenRefreshing: boolean;

  // Elements
  elements: Elements;
  elementsLoaded: boolean;
  elementsError: string | null;

  // Elements Mapping
  elementsMappings: ElementsMappings;
  elementsMappingsLoaded: boolean;
  elementsMappingsError: string | null;

  // Queries
  queries: Query[];
  queriesLoaded: boolean;
  queriesError: string | null;

  // Credentials
  credentials: Credentials[];
  credentialsLoaded: boolean;
  credentialsError: string | null;

  // Google Search Console
  googleConsoleAuthUrl: string | null;
  googleConsoleAuthUrlLoaded: boolean;
  googleSites: string[];
  googleSitesLoaded: boolean;
  googleSitesError: string | null;

  // Google Ads
  googleAdsAuthUrl: string | null;
  googleAdsAuthUrlLoaded: boolean;
  googleAdsAccounts: GoogleAdsAccounts;
  googleAdsAccountsLoaded: boolean;
  googleAdsAccountsError: string | null;
}

export type ContextProviderType = {
  call: (
    path: string,
    okCallback: (data: any) => void,
    errCallback: (error: any) => void,
    method: string,
    body: Record<string, unknown> | null,
  ) => void;
  dismissErrors: () => void;
  handleLogin: () => string;
  tokenIsValid: (token?: TokenType) => boolean;
  loadTokenData: (projectId: string) => TokenType | null;
  loadElements: () => void;
  findElementByName: (name: string) => Element | null;
  createElement: (
    element: Element,
    callback?: () => void,
    callbackErr?: () => void,
  ) => void;
  loadElementsMappings: () => void;
  setElementMapping: (
    elementMapping: ElementMapping,
    callback?: () => void,
  ) => void;
  deleteElementMapping: (
    elementMapping: ElementMapping,
    callback?: () => void,
  ) => void;
  updateElementMapping: (
    elementMapping: ElementMapping,
    callback?: () => void,
  ) => void;
  loadQueries: () => void;
  loadCredentials: (
    callback?: (data: Credentials[]) => void,
    callbackErr?: () => void,
  ) => void;
  setCredentials: (
    credentials: Credentials,
    callback?: () => void,
    callbackErr?: () => void,
  ) => void;
  deleteCredentials: (
    credentials: Credentials,
    callback?: () => void,
    callbackErr?: () => void,
  ) => void;
  getGoogleConsoleAuthUrl: (
    callback?: (url: string) => void,
    callbackErr?: () => void,
  ) => void;
  loadGoogleSites: () => void;
  getGoogleAdsAuthUrl: (
    callback?: (url: string) => void,
    callbackErr?: () => void,
  ) => void;
  loadGoogleAdsAccounts: (
    callback?: (accounts: GoogleAdsAccounts) => void,
    callbackErr?: () => void,
  ) => void;
} & ContextProviderState;

export const ContextDefaults: ContextProviderType = {
  // Call API
  call: () => {},

  // Errors
  dismissErrors: () => {},

  // Authentication
  projectId: null,
  token: null,
  tokenRefreshing: false,
  handleLogin: () => "ok",
  tokenIsValid: () => false,
  loadTokenData: () => null,

  // Elements
  elements: {},
  elementsLoaded: false,
  elementsError: null,
  loadElements: () => {},
  findElementByName: () => null,
  createElement: () => {},

  // Elements Mapping
  elementsMappings: { available_data_sources: [], data: [], errors: [] },
  elementsMappingsLoaded: false,
  elementsMappingsError: null,
  loadElementsMappings: () => {},
  setElementMapping: () => {},
  deleteElementMapping: () => {},
  updateElementMapping: () => {},

  // Queries
  queries: [],
  queriesLoaded: false,
  queriesError: null,
  loadQueries: () => {},

  // Credentials
  credentials: [],
  credentialsLoaded: false,
  credentialsError: null,
  loadCredentials: () => {},
  setCredentials: () => {},
  deleteCredentials: () => {},

  // Google Search
  googleConsoleAuthUrl: null,
  googleConsoleAuthUrlLoaded: false,
  getGoogleConsoleAuthUrl: () => {},
  googleSites: [],
  googleSitesLoaded: false,
  googleSitesError: null,
  loadGoogleSites: () => {},

  // Google Ads
  googleAdsAuthUrl: null,
  googleAdsAuthUrlLoaded: false,
  getGoogleAdsAuthUrl: () => {},
  googleAdsAccounts: {},
  googleAdsAccountsLoaded: false,
  googleAdsAccountsError: null,
  loadGoogleAdsAccounts: () => {},
};
export const Context =
  React.createContext<ContextProviderType>(ContextDefaults);

export function hideToken(credentials: Credentials) {
  if (credentials.type === "openai") {
    credentials.token = `sk-${"●".repeat(
      credentials.token.length - 7,
    )}${credentials.token.slice(-4)}`;
  } else if (credentials.type === "sklik") {
    const [prefix, suffix] = credentials.token.split("@", 2);
    credentials.token = `${"●".repeat(prefix.length)}@${suffix}`;
  }
}

class ContextProviderComponent extends Component<
  ContextProviderProps,
  ContextProviderState
> {
  constructor(props: ContextProviderProps) {
    super(props);
    this.state = {
      token: null,
      tokenRefreshing: false,
      projectId: null,
      elements: {},
      elementsLoaded: false,
      elementsError: null,
      elementsMappings: { available_data_sources: [], data: [], errors: [] },
      elementsMappingsLoaded: false,
      elementsMappingsError: null,
      queries: [],
      queriesLoaded: false,
      queriesError: null,
      credentials: [],
      credentialsLoaded: false,
      credentialsError: null,
      googleConsoleAuthUrl: null,
      googleConsoleAuthUrlLoaded: false,
      googleSites: [],
      googleSitesLoaded: false,
      googleSitesError: null,
      googleAdsAuthUrl: null,
      googleAdsAuthUrlLoaded: false,
      googleAdsAccounts: {},
      googleAdsAccountsLoaded: false,
      googleAdsAccountsError: null,
    };
  }

  componentDidMount() {
    const projectId = getProjectId(this.props.searchParams);
    if (projectId) {
      this.setState({ projectId });
    }
  }

  componentDidUpdate(
    _: Readonly<ContextProviderProps>,
    prevState: Readonly<ContextProviderState>,
  ): void {
    if (this.state.token?.locale !== prevState.token?.locale) {
      const { i18n } = this.props;
      const language = this.state.token?.locale.split("_", 1)[0] || "cs";
      if (i18n.language !== language) {
        void i18n.changeLanguage(language);
      }
    }
  }

  loadTokenData(projectId: string): TokenType | null {
    const tokenData = window.localStorage.getItem("accessToken" + projectId);
    if (tokenData) {
      const token = JSON.parse(tokenData);
      if (this.tokenIsValid(token)) {
        this.setState({ token, tokenRefreshing: false });
        return token;
      }
    }
    return null;
  }

  storeTokenData(data: TokenType, projectId: string) {
    window.localStorage.setItem(
      "accessToken" + projectId,
      JSON.stringify(data),
    );
    this.setState({
      token: data,
      projectId,
      tokenRefreshing: false,
    });
  }

  handleLogin() {
    if (!this.state.projectId) {
      return "missing_project_id";
    }

    if (this.loadTokenData(this.state.projectId)) {
      return "ok";
    }

    this.setState({ tokenRefreshing: true });
    const oauth2Code = this.props.searchParams.get("code");
    const location = this.props.searchParams.get("location") || "/elements";

    if (!oauth2Code) {
      return "missing_code";
    }

    const authQuery = new URLSearchParams();
    authQuery.append("code", oauth2Code);
    authQuery.append("location", location);

    const tokenUrl = `${readVar("REACT_APP_BACKEND_API_URL")}/projects/${
      this.state.projectId
    }/authenticate/?${authQuery.toString()}`;

    void fetch(tokenUrl)
      .then(async (response) => await response.json())
      .then((data: TokenType) => {
        if (data.access_token && this.state.projectId) {
          this.storeTokenData(data, this.state.projectId);
        }
      });

    return "ok";
  }

  tokenIsValid(token?: TokenType): boolean {
    return (
      (token?.access_token && Date.parse(token.expires_at) > Date.now()) ||
      false
    );
  }

  call(
    path: string,
    okCallback: (data: any) => void,
    errCallback: (error: any) => void,
    method: string = "GET",
    body: any = null,
    retries = 3,
  ) {
    const url = readVar("REACT_APP_BACKEND_API_URL") + path;
    const accessToken = this.state.token?.access_token;

    if (!this.state.projectId || !accessToken) {
      return;
    }

    fetch(url, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      method,
      body: body ? JSON.stringify(body) : null,
    })
      .then(async (res) => {
        if (res.ok) {
          return await res.json();
        }
        throw new APIError("api_fetch_error", res.status);
      })
      .then(okCallback)
      .catch((error) => {
        if (error.status >= 500 && retries > 0) {
          this.call(path, okCallback, errCallback, method, body, retries - 1);
        } else {
          errCallback(error);
        }
      });
  }

  loadElements() {
    const okCallback = (elements: Elements) => {
      this.setState({
        elements,
        elementsError: null,
        elementsLoaded: true,
      });
    };
    const errCallback = (error: any) => {
      this.setState({ elementsError: error.message, elementsLoaded: true });
    };
    this.call(
      "/projects/" + this.state.projectId + "/elements/",
      okCallback,
      errCallback,
    );
  }

  loadCredentials(
    callback?: (data: Credentials[]) => void,
    callbackErr?: () => void,
  ) {
    const okCallback = (data: Credentials[]) => {
      this.setState({
        credentials: data,
        credentialsError: null,
        credentialsLoaded: true,
        elementsMappings: {
          ...this.state.elementsMappings,
          available_data_sources: data.map((cred) => cred.type),
        },
      });
      if (callback) {
        callback(data);
      }
    };
    const errCallback = (error: any) => {
      this.setState({
        credentialsError: error.message,
        credentialsLoaded: true,
      });
      if (callbackErr) {
        callbackErr();
      }
    };
    this.call(
      "/projects/" + this.state.projectId + "/credentials/",
      okCallback,
      errCallback,
    );
  }

  setCredentials(
    credentials: Credentials,
    callback?: () => void,
    errCallback?: () => void,
  ) {
    const okCallback = () => {
      const newCredentials = this.state.credentials.filter(
        (cred: Credentials) => {
          return cred.type !== credentials.type;
        },
      );
      hideToken(credentials);
      newCredentials.push(credentials);

      const availableDataSources =
        this.state.elementsMappings.available_data_sources;
      const mappingsErrors = this.state.elementsMappings.errors.filter(
        (error) => !error.startsWith(credentials.type),
      );

      if (!availableDataSources.includes(credentials.type)) {
        availableDataSources.push(credentials.type);
      }

      this.setState({
        credentials: newCredentials,
        credentialsError: null,
        credentialsLoaded: true,
        elementsMappings: {
          ...this.state.elementsMappings,
          errors: mappingsErrors,
          available_data_sources: availableDataSources,
        },
      });
      if (callback) {
        callback();
      }
    };
    const errorCallback = (error: APIError) => {
      let message = error.message;
      switch (error.status) {
        case 401:
          message = `${credentials.type}_unauthorized`;
          break;
        case 403:
          message = `${credentials.type}_forbidden`;
          break;
      }
      this.setState({ credentialsError: message, credentialsLoaded: true });
      if (errCallback) {
        errCallback();
      }
    };
    this.call(
      "/projects/" + this.state.projectId + "/credentials/",
      okCallback,
      errorCallback,
      "PUT",
      credentials,
    );
  }

  deleteCredentials(
    credentials: Credentials,
    callback?: () => void,
    errCallback?: () => void,
  ) {
    const okCallback = () => {
      const newCredentials = this.state.credentials.filter(
        (cred: Credentials) => {
          return cred.type !== credentials.type;
        },
      );
      let availableDataSources =
        this.state.elementsMappings.available_data_sources;
      if (availableDataSources.includes(credentials.type)) {
        availableDataSources = availableDataSources.filter(
          (source) => source !== credentials.type,
        );
        this.setState({
          elementsMappings: {
            ...this.state.elementsMappings,
            available_data_sources: availableDataSources,
          },
        });
      }
      this.setState({
        credentials: newCredentials,
        credentialsError: null,
        credentialsLoaded: true,
      });
      if (callback) {
        callback();
      }
    };
    const errorCallback = (error: any) => {
      this.setState({
        credentialsError: error.message,
        credentialsLoaded: true,
      });
      if (errCallback) {
        errCallback();
      }
    };
    this.call(
      "/projects/" + this.state.projectId + "/credentials/",
      okCallback,
      errorCallback,
      "DELETE",
      credentials,
    );
  }

  loadElementsMappings() {
    const okCallback = (mappings: ElementsMappings) => {
      this.setState({
        elementsMappings: mappings,
        elementsMappingsError: null,
        elementsMappingsLoaded: true,
      });
    };
    const errCallback = (error: any) => {
      this.setState({
        elementsMappingsError: error.message,
        elementsMappingsLoaded: true,
      });
    };
    this.call(
      "/projects/" + this.state.projectId + "/element-maps/",
      okCallback,
      errCallback,
    );
  }

  findElementByName(fullName: string) {
    let elements = this.state.elements;
    const parts = fullName.split(" | ");

    for (const part of parts) {
      const children = part in elements ? elements[part].children : undefined;
      if (children) {
        elements = children;
      }
    }

    return elements[parts[parts.length - 1]] || null;
  }

  createElement(element: Element, callback?: () => void) {
    const name = element.name;
    if (!name) {
      return;
    }

    const okCallback = (data: Element) => {
      const elements = this.state.elements;
      elements[name] = data;
      this.setState({
        elements,
        elementsError: null,
        elementsLoaded: true,
      });
      if (callback) {
        callback();
      }
    };
    const errCallback = (error: any) => {
      this.setState({ elementsError: error.message, elementsLoaded: true });
    };
    this.call(
      "/projects/" + this.state.projectId + "/elements/",
      okCallback,
      errCallback,
      "POST",
      element,
    );
  }

  setElementMapping(mapping: ElementMapping, callback?: () => void) {
    const okCallback = (created: ElementMapping) => {
      this.state.elementsMappings.data.push(created);
      this.setState({
        elementsMappings: this.state.elementsMappings,
        elementsMappingsError: null,
        elementsMappingsLoaded: true,
      });
      if (callback) {
        callback();
      }
    };
    const errCallback = (error: any) => {
      this.setState({
        elementsMappingsError: error.message,
        elementsMappingsLoaded: true,
      });
    };
    this.call(
      "/projects/" + this.state.projectId + "/element-maps/",
      okCallback,
      errCallback,
      "POST",
      mapping,
    );
  }

  updateElementMapping(mapping: ElementMapping, callback?: () => void) {
    const okCallback = () => {
      const newElementsMappingData = this.state.elementsMappings.data.map(
        (m: ElementMapping) => {
          if (m.id === mapping.id) {
            return mapping;
          }
          return m;
        },
      );
      this.setState({
        elementsMappings: {
          ...this.state.elementsMappings,
          data: newElementsMappingData,
        },
        elementsMappingsError: null,
        elementsMappingsLoaded: true,
      });
      if (callback) {
        callback();
      }
    };
    const errCallback = (error: any) => {
      this.setState({
        elementsMappingsError: error.message,
        elementsMappingsLoaded: true,
      });
    };
    this.call(
      "/projects/" + this.state.projectId + "/element-maps/",
      okCallback,
      errCallback,
      "PUT",
      mapping,
    );
  }

  deleteElementMapping(mapping: ElementMapping, callback?: () => void) {
    const okCallback = () => {
      const newElementsMappingData = this.state.elementsMappings.data.filter(
        (m: ElementMapping) => {
          return m.id !== mapping.id;
        },
      );
      this.setState({
        elementsMappings: {
          ...this.state.elementsMappings,
          data: newElementsMappingData,
        },
        elementsMappingsError: null,
        elementsMappingsLoaded: true,
      });
      if (callback) {
        callback();
      }
    };
    const errCallback = (error: any) => {
      this.setState({
        elementsMappingsError: error.message,
        elementsMappingsLoaded: true,
      });
    };
    this.call(
      "/projects/" + this.state.projectId + "/element-maps/",
      okCallback,
      errCallback,
      "DELETE",
      mapping,
    );
  }

  loadQueries() {
    const okCallback = (queries: Query[]) => {
      this.setState({
        queries,
        queriesError: null,
        queriesLoaded: true,
      });
    };
    const errCallback = (error: any) => {
      this.setState({
        queriesError: error.message,
        queriesLoaded: true,
      });
    };
    this.call(
      "/projects/" + this.state.projectId + "/queries/",
      okCallback,
      errCallback,
    );
  }

  getGoogleConsoleAuthUrl(
    callback?: (url: string) => void,
    callbackErr?: () => void,
  ) {
    const okCallback = (url: string) => {
      this.setState({
        googleConsoleAuthUrl: url,
        googleConsoleAuthUrlLoaded: true,
      });
      if (callback) {
        callback(url);
      }
    };
    const errCallback = () => {
      this.setState({
        googleConsoleAuthUrlLoaded: true,
      });
      if (callbackErr) {
        callbackErr();
      }
    };
    this.call(
      "/projects/" + this.state.projectId + "/google/auth/console/",
      okCallback,
      errCallback,
      "GET",
    );
  }

  getGoogleAdsAuthUrl(
    callback?: (url: string) => void,
    callbackErr?: () => void,
  ) {
    const okCallback = (url: string) => {
      this.setState({
        googleAdsAuthUrl: url,
        googleAdsAuthUrlLoaded: true,
      });
      if (callback) {
        callback(url);
      }
    };
    const errCallback = () => {
      this.setState({
        googleAdsAuthUrlLoaded: true,
      });
      if (callbackErr) {
        callbackErr();
      }
    };
    this.call(
      "/projects/" + this.state.projectId + "/google/auth/ads/",
      okCallback,
      errCallback,
      "GET",
    );
  }

  loadGoogleSites() {
    const okCallback = (sites: string[]) => {
      this.setState({
        googleSites: sites,
        googleSitesLoaded: true,
        googleSitesError: sites.length > 0 ? null : "google_no_sites",
      });
    };
    const errCallback = (error: APIError) => {
      let errorMessage = "google_api_error";
      switch (error.status) {
        case 403:
          errorMessage = "google_forbidden";
          break;
        case 404:
          errorMessage = "google_no_sites";
          break;
      }
      this.state.elementsMappings.errors.push(errorMessage);
      this.setState({
        googleSitesLoaded: true,
        googleSitesError: errorMessage,
        elementsMappings: this.state.elementsMappings,
      });
    };
    this.call(
      "/projects/" + this.state.projectId + "/google/sites/",
      okCallback,
      errCallback,
      "GET",
    );
  }

  loadGoogleAdsAccounts(
    callback?: (accounts: GoogleAdsAccounts) => void,
    callbackErr?: () => void,
  ) {
    const okCallback = (accounts: GoogleAdsAccounts) => {
      this.setState({
        googleAdsAccounts: accounts,
        googleAdsAccountsLoaded: true,
        googleAdsAccountsError:
          Object.keys(accounts).length > 0 ? null : "google_no_customer_ids",
      });
      if (callback) {
        callback(accounts);
      }
    };
    const errCallback = (error: APIError) => {
      let errorMessage = "google_api_error";
      switch (error.status) {
        case 403:
          errorMessage = "google_forbidden";
          break;
        case 404:
          errorMessage = "google_no_customer_ids";
          break;
      }
      this.state.elementsMappings.errors.push(errorMessage);
      this.setState({
        googleAdsAccountsLoaded: true,
        googleAdsAccountsError: errorMessage,
        elementsMappings: this.state.elementsMappings,
      });
      if (callbackErr) {
        callbackErr();
      }
    };
    this.call(
      "/projects/" + this.state.projectId + "/google/ads/customers/",
      okCallback,
      errCallback,
      "GET",
    );
  }

  dismissErrors() {
    this.setState({
      elementsError: null,
      credentialsError: null,
      elementsMappingsError: null,
      queriesError: null,
      googleSitesError: null,
      googleAdsAccountsError: null,
    });
  }

  render() {
    if (!this.state.projectId) {
      return <ErrorCodeMessage code="missing_project_id" />;
    }

    const value = {
      ...this.state,

      // Errors
      dismissErrors: this.dismissErrors.bind(this),

      // Authentication
      handleLogin: this.handleLogin.bind(this),
      tokenIsValid: this.tokenIsValid.bind(this),
      loadTokenData: this.loadTokenData.bind(this),

      // API calls
      call: this.call.bind(this),
      loadElements: this.loadElements.bind(this),
      findElementByName: this.findElementByName.bind(this),
      createElement: this.createElement.bind(this),
      loadElementsMappings: this.loadElementsMappings.bind(this),
      setElementMapping: this.setElementMapping.bind(this),
      updateElementMapping: this.updateElementMapping.bind(this),
      deleteElementMapping: this.deleteElementMapping.bind(this),
      loadCredentials: this.loadCredentials.bind(this),
      setCredentials: this.setCredentials.bind(this),
      deleteCredentials: this.deleteCredentials.bind(this),
      loadQueries: this.loadQueries.bind(this),
      getGoogleConsoleAuthUrl: this.getGoogleConsoleAuthUrl.bind(this),
      loadGoogleSites: this.loadGoogleSites.bind(this),
      getGoogleAdsAuthUrl: this.getGoogleAdsAuthUrl.bind(this),
      loadGoogleAdsAccounts: this.loadGoogleAdsAccounts.bind(this),
    };

    return (
      <Context.Provider value={value}>{this.props.children}</Context.Provider>
    );
  }
}

export const ContextProvider = ({ children }: { children: any }) => {
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();
  return (
    <ContextProviderComponent searchParams={searchParams} i18n={i18n}>
      {children}
    </ContextProviderComponent>
  );
};
