import React, { Component, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Context,
  type ContextProviderType,
  type ElementMapping,
  type ElementsMappings,
  type InputConversion,
  type OutputConversion,
} from "./ContextProvider";
import {
  getDataColumnValue,
  getDataSourceColumnValue,
  getSourceTypeForInputType,
} from "./DataSources";
import { Icon } from "../helpers/Icons";
import { ErrorCodeMessage, InfoMessage } from "../helpers/Messages";
import { ClickingGoatTip } from "../helpers/Tips";
import { ModalConfirm } from "../helpers/Modals";
import { ButtonPrimary, ButtonText } from "../helpers/Buttons";
import { Block } from "../helpers/Blocks";
import { BigParagraph } from "../helpers/Paragraphs";
import {
  DataSourceSelect,
  DataValueSelect,
  InputElementSelect,
  OutputElementSelect,
  QuerySelect,
} from "../helpers/Forms";
import { type Question, QuestionHelper, invalidInputForOpenAI } from "./OpenAi";
import { useTranslation, withTranslation } from "react-i18next";
import { Preview } from "./Preview";
import { MergadoLink } from "../helpers/Mergado";
import {
  type Request as IceCatRequest,
  ProductItem,
  dumpIndexedProductItem,
} from "./IceCat";
import {
  getOutputTypes,
  invalidInputForGoogle,
  type SearchRequest as GoogleSearchRequest,
} from "./Google";

interface ElementRowProps {
  disabled: boolean;
  mapper: Mapper;
  handleDeleteConfirm: (mapping: ElementMapping) => void;
  handleActivate: (mapping: ElementMapping) => void;
  handleEditIsOn: (mapping: ElementMapping) => void;
}

class Mapper implements ElementMapping {
  id?: number;
  is_active: boolean;
  input: InputConversion;
  output: OutputConversion;
  query_id?: number;

  constructor({ id, is_active, input, output, query_id }: ElementMapping) {
    this.id = id;
    this.is_active = is_active;
    this.input = input;
    this.output = output;
    this.query_id = query_id;
  }

  compare(other: Mapper) {
    if (this.input.type < other.input.type) return -1;
    if (this.input.type > other.input.type) return 1;

    if (this.getColumnInputName() < other.getColumnInputName()) return -1;
    if (this.getColumnInputName() > other.getColumnInputName()) return 1;

    if (this.getColumnOutputType() < other.getColumnOutputType()) return -1;
    if (this.getColumnOutputType() > other.getColumnOutputType()) return 1;

    if (this.getColumnOutputName() < other.getColumnOutputName()) return -1;
    if (this.getColumnOutputName() > other.getColumnOutputName()) return 1;

    return 0;
  }

  getKey() {
    return `${this.input.type}-${this.output.type}-${this.id}`;
  }

  getColumnInputType() {
    return getDataSourceColumnValue(this.input.type);
  }

  getColumnInputName() {
    switch (this.input.type) {
      case "OPEN_AI_QUESTION":
        return truncate(this.getQuestionHelper().getInputName(), 150);
      case "SKLIK_KEYWORD":
      case "GOOGLE_ADS":
        return (this.input.args as string).toString();
      case "ICE_CAT_REQUEST":
        return (this.input.args as IceCatRequest).element;
      case "GOOGLE_SEARCH_ANALYTICS":
        return (this.input.args as GoogleSearchRequest).element;
    }
  }

  getColumnOutputType() {
    const dataColumnValue = getDataColumnValue(this.output.type);
    switch (this.input.type) {
      case "OPEN_AI_QUESTION":
        return this.getQuestionHelper().getFullOutputType(dataColumnValue);
      case "SKLIK_KEYWORD":
      case "GOOGLE_ADS":
        return dataColumnValue;
      case "ICE_CAT_REQUEST": {
        const name = dumpIndexedProductItem(
          (this.input.args as IceCatRequest).output_item_value,
        );
        for (const [key, value] of Object.entries(ProductItem)) {
          if (key === name) {
            return value;
          }
        }
        return name;
      }
      case "GOOGLE_SEARCH_ANALYTICS":
        return dataColumnValue;
    }
  }

  getColumnOutputName() {
    switch (this.input.type) {
      case "OPEN_AI_QUESTION":
      case "SKLIK_KEYWORD":
      case "ICE_CAT_REQUEST":
      case "GOOGLE_ADS":
      case "GOOGLE_SEARCH_ANALYTICS":
        return this.output.args;
    }
  }

  getQuestionHelper(): QuestionHelper {
    const defaultKind = "manual";
    if (this.input.type === "OPEN_AI_QUESTION") {
      return new QuestionHelper(this.input.args as Question);
    } else {
      return new QuestionHelper(QuestionHelper.getDefaultArgs(defaultKind));
    }
  }
}

function truncate(str: string, n: number) {
  return str.length > n ? str.slice(0, n - 1) + "…" : str;
}

function ElementRow({
  mapper,
  disabled,
  handleDeleteConfirm,
  handleActivate,
  handleEditIsOn,
}: ElementRowProps) {
  const { t } = useTranslation();
  const { projectId, queries } = useContext<ContextProviderType>(Context);

  const activeClass = mapper.is_active ? "active" : "inactive";
  const disabledClass = disabled ? "disabled" : "enabled";

  const queriesMap = queries.reduce((result: Record<number, string>, query) => {
    result[Number(query.id)] = query.name;
    return result;
  }, {});
  let queryLink = (
    <MergadoLink path={`/projects/${projectId}/queries/`}>
      {t("elements.table.query.allProducts.label")}
    </MergadoLink>
  );
  if (mapper.query_id && !isNaN(mapper.query_id)) {
    queryLink = (
      <MergadoLink path={`/projects/${projectId}/queries/${mapper.query_id}`}>
        {truncate(queriesMap[mapper.query_id] || `#${mapper.query_id}`, 35)}
      </MergadoLink>
    );
  }

  return (
    <tr key={mapper.getKey()} className={`${activeClass} ${disabledClass}`}>
      <td className="source">{mapper.getColumnInputType()}</td>
      <td>{mapper.getColumnInputName()}</td>
      <td>{queryLink}</td>
      <td>{mapper.getColumnOutputType()}</td>
      <td>{mapper.getColumnOutputName()}</td>
      <td className="actions">
        <label
          className="switch"
          title={
            disabled
              ? String(t("element.turnOn.disabled.label"))
              : mapper.is_active
              ? String(t("element.turnOff.label"))
              : String(t("element.turnOn.label"))
          }
        >
          <input
            type="checkbox"
            checked={mapper.is_active}
            onChange={() => {
              handleActivate(mapper);
            }}
            disabled={disabled}
          />
          <span className="slider round"></span>
        </label>
        <a
          className="icon action-edit"
          title={
            disabled
              ? String(t("element.update.disabled.label"))
              : String(t("element.update.label"))
          }
          onClick={
            disabled
              ? undefined
              : () => {
                  handleEditIsOn(mapper);
                }
          }
        >
          <Icon name="edit" />
        </a>
        <a
          className="icon danger action-delete"
          title={String(t("element.delete.label"))}
          onClick={() => {
            handleDeleteConfirm(mapper);
          }}
        >
          <Icon name="delete" />
        </a>
      </td>
    </tr>
  );
}

interface ElementsTableProps {
  t: (text: string) => string;
  mappings: ElementsMappings;
  handleEditIsOn: (element: ElementMapping) => void;
  handleDeleteConfirm: (element: ElementMapping) => void;
  handleActivate: (element: ElementMapping) => void;
}

class ElementsTableComponent extends Component<ElementsTableProps> {
  sortedMappings(): ElementMapping[] {
    return this.props.mappings.data.slice(0).sort((a, b) => {
      const mapperA = new Mapper(a);
      const mapperB = new Mapper(b);

      return mapperA.compare(mapperB);
    });
  }

  render() {
    const { t } = this.props;
    return (
      <table className="datagrid fixed">
        <thead>
          <tr>
            <th className="w10p">{t("elements.table.source.label")}</th>
            <th className="w30p">{t("elements.table.input.label")}</th>
            <th className="w20p">{t("elements.table.query.label")}</th>
            <th className="w10p">{t("elements.table.source.value.label")}</th>
            <th className="w10p">{t("elements.table.output.label")}</th>
            <th className="w10p actions"></th>
          </tr>
        </thead>
        <tbody>
          {this.sortedMappings().map((mapping) => {
            const mapper = new Mapper(mapping);
            return (
              <ElementRow
                mapper={mapper}
                handleDeleteConfirm={this.props.handleDeleteConfirm}
                handleActivate={this.props.handleActivate}
                handleEditIsOn={this.props.handleEditIsOn}
                disabled={
                  !this.props.mappings.available_data_sources.includes(
                    getSourceTypeForInputType(mapping.input.type),
                  )
                }
                key={`${mapper.getKey()}`}
              />
            );
          })}
        </tbody>
      </table>
    );
  }
}

const ElementsTable = withTranslation()(ElementsTableComponent);

interface ElementPutProps {
  t: (key: string) => string;
  mapper?: Mapper;
  handleClose: () => void;
}

interface ElementPutState {
  loading: boolean;
  error: string | null;
  inputType: InputConversion["type"] | null;
  inputArgs: InputConversion["args"] | null;
  outputType: OutputConversion["type"] | null;
  outputArgs: OutputConversion["args"] | null;
  outputArgsDefault: OutputConversion["args"] | null;
  queryId: number | null;
  inputTypeClass?: string;
  inputArgsClass?: string;
  outputTypeClass?: string;
  outputArgsClass?: string;
}

class ElementPutComponent extends Component<ElementPutProps, ElementPutState> {
  static contextType = Context;
  context!: React.ContextType<typeof Context>;

  constructor(props: ElementPutProps) {
    super(props);

    const inputType = props.mapper?.input?.type || null;
    const inputArgs = props.mapper?.input?.args || null;
    const outputType = props.mapper?.output?.type || null;
    const outputArgs = props.mapper?.output?.args || null;
    const queryId = props.mapper?.query_id || null;

    this.state = {
      loading: false,
      error: null,
      inputType,
      inputArgs,
      outputType,
      outputArgs,
      outputArgsDefault: this.getDefaultOutputArgs(outputType),
      queryId,
    };
  }

  componentDidMount() {
    if (!this.context.elementsMappingsLoaded) {
      this.context.loadElementsMappings();
    }
    if (!this.context.elementsLoaded) {
      this.context.loadElements();
    }
  }

  getInputMapping() {
    if (this.state.inputArgs === null || this.state.inputType === null) {
      return null;
    } else {
      return {
        args: this.state.inputArgs,
        type: this.state.inputType,
      };
    }
  }

  getOutputMapping() {
    if (this.state.outputArgs === null || this.state.outputType === null) {
      return null;
    } else {
      return {
        args: this.state.outputArgs,
        type: this.state.outputType,
      };
    }
  }

  getDefaultOutputArgs(outputType: OutputConversion["type"] | null) {
    switch (outputType) {
      case null:
        return null;
      case "GPT35_ANSWER":
      case "GPT35_INSTRUCT_ANSWER":
      case "GPT40_ANSWER":
      case "GPT4O_ANSWER":
      case "DAVINCI002_ANSWER":
      case "BABBAGE002_ANSWER":
        return "CG_GPT_ANSWER";
      case "ICE_CAT_RESPONSE":
        return "CG_ICECAT_RESPONSE";
      default:
        return `CG_${outputType}`;
    }
  }

  getListOfDefaultOutputArgs() {
    return [
      "CG_GPT_ANSWER",
      "CG_SKLIK_AVG_CPC",
      "CG_SKLIK_AVG_SEARCH_COUNT",
      "CG_ICECAT_RESPONSE",
      "CG_GOOGLE_SEARCH_CLICKS",
      "CG_GOOGLE_SEARCH_IMPRESSIONS",
      "CG_GOOGLE_SEARCH_CTR",
      "CG_GOOGLE_SEARCH_POSITION",
      "CG_GOOGLE_SEARCH_TOP_URL",
      "CG_GOOGLE_ADS_CLICKS",
      "CG_GOOGLE_ADS_COST",
      "CG_GOOGLE_ADS_CONVERSIONS",
      "CG_GOOGLE_ADS_CONVERSIONS_VALUE",
      "CG_GOOGLE_ADS_IMPRESSIONS",
      "CG_GOOGLE_ADS_CTR",
      "CG_GOOGLE_ADS_AVERAGE_CPC",
    ];
  }

  changeInputType(inputType: InputConversion["type"] | null) {
    this.setState({
      inputType,
      inputArgs: null,
      outputType: null,
      outputArgs: null,
      outputArgsDefault: null,
      inputTypeClass: undefined,
      inputArgsClass: undefined,
      outputArgsClass: undefined,
      outputTypeClass: undefined,
    });
  }

  changeQueryId(queryId: number | null) {
    this.setState({ queryId });
  }

  changeInputArgs(inputArgs: InputConversion["args"] | null) {
    this.setState({
      inputArgs,
      inputArgsClass: undefined,
    });
    if (this.state.inputType === "ICE_CAT_REQUEST") {
      this.changeOutputType("ICE_CAT_RESPONSE");
    }
  }

  changeOutputType(outputType: OutputConversion["type"] | null) {
    const defaultOutputArgs = this.getDefaultOutputArgs(outputType);
    this.setState({
      outputType,
      outputTypeClass: undefined,
      outputArgsDefault: defaultOutputArgs,
    });

    if (
      this.state.outputArgs === null ||
      this.state.outputArgs in this.getListOfDefaultOutputArgs()
    ) {
      this.changeOutputArgs(defaultOutputArgs);
    }
  }

  changeOutputArgs(outputArgs: OutputConversion["args"] | null) {
    this.setState({
      outputArgs,
      outputArgsClass: undefined,
    });
  }

  elementMappingExists(outputArgs: OutputConversion["args"] | null) {
    return this.context.elementsMappings.data.some((m: ElementMapping) => {
      return (
        this.props.mapper?.id !== m.id &&
        m.is_active &&
        m.output.args === outputArgs
      );
    });
  }

  displayDataSelect() {
    return (
      !this.getOpenAiPrompt()?.isExcel() &&
      this.state.inputType !== "ICE_CAT_REQUEST"
    );
  }

  getOpenAiPrompt() {
    if (
      this.state.inputType === "OPEN_AI_QUESTION" &&
      this.state.inputArgs !== null
    ) {
      return new QuestionHelper(this.state.inputArgs as Question);
    } else {
      return null;
    }
  }

  getGoogleSearchOutputTypes() {
    if (
      this.state.inputType === "GOOGLE_SEARCH_ANALYTICS" &&
      (this.state.inputArgs as GoogleSearchRequest)
    ) {
      return getOutputTypes(this.state.inputArgs as GoogleSearchRequest);
    }
  }

  render() {
    const { t } = this.props;
    const handleBack = () => {
      this.props.handleClose();
      this.setState({ loading: false });
      this.context.dismissErrors();
    };
    const handleSave = () => {
      let isValid = true;

      if (this.state.inputType === null) {
        this.setState({ inputTypeClass: "invalid" });
        isValid = false;
      }
      if (
        this.state.inputArgs === null ||
        invalidInputForOpenAI(this.state.inputType, this.state.inputArgs) ||
        invalidInputForGoogle(this.state.inputType, this.state.inputArgs)
      ) {
        this.setState({ inputArgsClass: "invalid" });
        isValid = false;
      }
      if (this.state.outputType === null) {
        this.setState({ outputTypeClass: "invalid" });
        isValid = false;
      }
      if (this.state.outputArgs === null) {
        this.setState({ outputArgsClass: "invalid" });
        isValid = false;
      }
      if (this.elementMappingExists(this.state.outputArgs)) {
        this.setState({
          error: "element_mapping_exists",
          outputArgsClass: "invalid",
        });
        isValid = false;
      }

      if (isValid) {
        this.setState({ loading: true });

        const operation = this.props.mapper
          ? this.context.updateElementMapping
          : this.context.setElementMapping;

        const createOrUpdate = () => {
          operation(
            {
              id: this.props.mapper?.id || undefined,
              is_active: true,
              query_id: this.state.queryId || undefined,
              input: {
                // @ts-expect-error
                type: this.state.inputType,
                // @ts-expect-error
                args: this.state.inputArgs,
              },
              output: {
                // @ts-expect-error
                type: this.state.outputType,
                // @ts-expect-error
                args: this.state.outputArgs,
              },
            },
            handleBack,
          );
        };

        if (
          this.state.outputArgs &&
          this.context.findElementByName(this.state.outputArgs) === null
        ) {
          this.context.createElement(
            {
              name: this.state.outputArgs,
              is_attribute: false,
              origin: "from_rule",
              hidden: false,
            },
            createOrUpdate,
          );
        } else {
          createOrUpdate();
        }
      }
    };

    let errorMessage;
    const error = this.state.error || this.context.elementsMappingsError;

    if (error) {
      errorMessage = <ErrorCodeMessage code={error} />;
    }

    let dataValueSelect;
    if (this.displayDataSelect()) {
      const outputTypes =
        this.getOpenAiPrompt()?.getOutputTypes() ||
        this.getGoogleSearchOutputTypes();

      dataValueSelect = (
        <DataValueSelect
          disabled={this.state.inputArgs === null}
          source={this.state.inputType}
          value={this.state.outputType}
          onChange={this.changeOutputType.bind(this)}
          className={this.state.outputTypeClass}
          outputTypes={outputTypes}
        />
      );
    }

    return (
      <Block>
        <div className="vertical-form with-preview">
          <div className="tiles">
            <div className="tile two-thirds">
              {errorMessage}
              <DataSourceSelect
                sources={this.context.elementsMappings.available_data_sources}
                loading={!this.context.elementsMappingsLoaded}
                value={this.state.inputType}
                onChange={this.changeInputType.bind(this)}
                className={this.state.inputTypeClass}
              />
              <QuerySelect
                queries={this.context.queries}
                loading={!this.context.queriesLoaded}
                value={this.state.queryId}
                onChange={this.changeQueryId.bind(this)}
                disabled={this.state.inputType === null}
              />
              <InputElementSelect
                elements={this.context.elements}
                loading={!this.context.elementsLoaded}
                inputType={this.state.inputType}
                value={this.state.inputArgs}
                onChange={this.changeInputArgs.bind(this)}
                onChangeOutputType={this.changeOutputType.bind(this)}
                className={this.state.inputArgsClass}
                disabled={this.state.inputType === null}
              />
              {dataValueSelect}
              <OutputElementSelect
                elements={this.context.elements}
                loading={!this.context.elementsLoaded}
                disabled={this.state.outputType === null}
                value={this.state.outputArgs}
                onChange={this.changeOutputArgs.bind(this)}
                className={this.state.outputArgsClass}
                defaultValue={this.state.outputArgsDefault}
              />
              <div className="submit-area">
                <ButtonPrimary onClick={handleSave}>
                  <Icon name={this.state.loading ? "loading" : "check"} />
                  <span>{t("element.save.label")}</span>
                </ButtonPrimary>
                <ButtonText onClick={handleBack}>
                  <Icon name="arrow-left" />
                  <span>{t("element.back.label")}</span>
                </ButtonText>
              </div>
            </div>
            <Preview
              queryId={this.state.queryId}
              input={this.getInputMapping()}
              output={this.getOutputMapping()}
            />
          </div>
        </div>
      </Block>
    );
  }
}

const ElementPut = withTranslation()(ElementPutComponent);

interface ElementsProps {
  t: (text: string, options?: any) => string;
}

interface ElementsState {
  createElement: boolean;
  editElement: ElementMapping | null;
  deleteElement: ElementMapping | null;
}

class ElementsPageComponent extends Component<ElementsProps, ElementsState> {
  static contextType = Context;
  context!: React.ContextType<typeof Context>;

  constructor(props: ElementsProps) {
    super(props);
    this.state = {
      createElement: false,
      editElement: null,
      deleteElement: null,
    };
  }

  componentDidMount() {
    if (!this.context.elementsMappingsLoaded) {
      this.context.loadElementsMappings();
    }
    if (!this.context.queriesLoaded) {
      this.context.loadQueries();
    }
  }

  render() {
    const { t } = this.props;

    if (!this.context.elementsMappingsLoaded) {
      return <InfoMessage text={t("info.elements.loading.text")} />;
    }

    if (this.context.elementsMappingsError !== null) {
      return <ErrorCodeMessage code={this.context.elementsMappingsError} />;
    }

    const handleCreateIsOn = (event: any) => {
      event.currentTarget.blur();
      this.setState({ createElement: true });
    };
    const handleEditIsOn = (element: ElementMapping) => {
      this.setState({ editElement: element });
    };
    const handleActivate = (element: ElementMapping) => {
      element.is_active = !element.is_active;
      this.context.updateElementMapping(element);
    };

    const handleDelete = () => {
      if (this.state.deleteElement) {
        this.context.deleteElementMapping(
          this.state.deleteElement,
          handleDeleteClose,
        );
      }
    };
    const handleDeleteClose = () => {
      this.setState({ deleteElement: null });
    };
    const handleDeleteConfirm = (deleteElement: ElementMapping) => {
      this.setState({ deleteElement });
    };

    const handleClose = () => {
      this.setState({
        createElement: false,
        editElement: null,
        deleteElement: null,
      });
      this.context.dismissErrors();
    };
    const handleCloseButton = (event: any) => {
      event.currentTarget.blur();
      handleClose();
    };

    const noDataSourcesAvailable =
      this.context.elementsMappings.available_data_sources.length === 0;
    const noElementsCreated =
      !this.state.createElement &&
      this.context.elementsMappings.data.length === 0;

    let header = t("elements.overview.header");
    let headerButton = (
      <ButtonPrimary
        onClick={handleCreateIsOn}
        disabled={noDataSourcesAvailable}
      >
        <Icon name="plus" />
        <span>{t("element.create.label")}</span>
      </ButtonPrimary>
    );
    let body = (
      <div>
        <ClickingGoatTip />
        <ElementsTable
          handleDeleteConfirm={handleDeleteConfirm}
          handleActivate={handleActivate}
          handleEditIsOn={handleEditIsOn}
          mappings={this.context.elementsMappings}
        />
      </div>
    );
    let footer;

    if (noDataSourcesAvailable) {
      body = (
        <Block>
          {t("elements.overview.empty.label") + " "}
          <Link to="/sources">
            {t("elements.overview.empty.connect.label")}
          </Link>
          .
        </Block>
      );
    } else if (noElementsCreated) {
      body = <Block>{t("elements.overview.emptyButConnected.label")}</Block>;
    } else if (this.state.createElement) {
      header = t("element.create.label");
      headerButton = (
        <ButtonText onClick={handleCloseButton}>
          <Icon name="arrow-left" />
          <span>{t("element.back.header.label")}</span>
        </ButtonText>
      );
      body = <ElementPut handleClose={handleClose} />;
    } else if (this.state.editElement) {
      header = t("element.update.label");
      headerButton = (
        <ButtonText onClick={handleCloseButton}>
          <Icon name="arrow-left" />
          <span>{t("element.back.header.label")}</span>
        </ButtonText>
      );
      body = (
        <ElementPut
          handleClose={handleClose}
          mapper={
            this.state.editElement
              ? new Mapper(this.state.editElement)
              : undefined
          }
        />
      );
    } else if (this.state.deleteElement) {
      footer = (
        <ModalConfirm
          handleConfirm={handleDelete}
          handleClose={handleDeleteClose}
          confirmText={t("element.delete.confirm.label")}
          closeText={t("element.delete.cancel.label")}
        >
          <h2>{t("element.delete.label")}</h2>
          <BigParagraph>
            {t("element.delete.confirm.header", {
              elementName: this.state.deleteElement.output.args,
            })}
          </BigParagraph>
        </ModalConfirm>
      );
    }

    return (
      <Block title={header} smallTitle={headerButton}>
        {body}
        {footer}
      </Block>
    );
  }
}

export const ElementsPage = withTranslation()(ElementsPageComponent);
